import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import React, {Component} from 'react';
import { Route, Link, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const API_URL = "https://api.tweetminder.com";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;


class TwitterAuth extends React.Component {
     componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const oauth_token = urlParams.get("oauth_token");
        const oauth_verifier = urlParams.get("oauth_verifier");

        axios.post(API_URL + "/api/twitter-auth", {oauth_token, oauth_verifier}, )
            .then((resp) => {
                window.location.href = "/";
                window.location.reload();
            }).catch((error) => {
        })
    }

    render() {
        return (
            <div></div>
        )
    }
}


class LandingPage extends React.Component {



    render() {
        return (
            <div className="App">
                <header className="App-header" style={{alignItems: "center"}}>
                    <div style={{width: window.innerWidth < 800 ? "95%" : 800, marginTop: 150}}>
                    <h2>TweetMinder</h2>
                        <div style={{textAlign: "left"}}>
                    <p style={{marginBottom: 0}}>
                        Over the course of consuming Twitter you've liked thousands of tweets.
                    </p>
                    <p style={{marginBottom: 0}}>
                        These tweets contain some great insights, business ideas, book recommendations and more.
                    </p>
                    <p style={{marginBottom: 0}}>
                        TweetMinder is a way to resurface those great tweets you've engaged with, helping trigger new ideas.
                    </p>
                    <p style={{marginBottom: 0}}>
                        TweetMinder will show you 20 randomly liked tweets every time you visit the page.
                    </p>

                        </div>

                        <div style={{width: window.innerWidth < 800 ? "95%" : 800, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <div
                        className={"hover"}
                        style={{backgroundColor: "#1da1f2", paddingLeft: 13, width: 200, marginTop: 50,paddingRight: 13, paddingTop: 8, paddingBottom: 8, borderRadius: 8}}>
                    <a
                        onClick={() => {
                            axios.get(API_URL + "/get-request-token")
                                .then(response => {
                                    window.location.href = response.data.twitter_link;
                                }).catch(errors => {
                            })
                        }}

                    >
                        Sign in with twitter
                    </a>
                    </div>
                        </div>
                    </div>
                                    <div style={{marginTop: "auto", color: "white", marginBottom: 10, fontSize: 18, display: "flex", flexDirection: "row", alignItems: "center"}}>

                                        <div>Created by</div> <div
                                        className={"hover"}
                                        onClick={() => {
                                            window.location = "https://twitter.com/@jay_mu_"
                                        }}
                                        style={{marginLeft: 10, color:"#1da1f2" }}>@jay_mu_</div>
                                    </div>
                </header>


            </div>
        );
    }
}

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            embeds: null,
        }

        this.logout = this.logout.bind(this);
        this.getRandomTweets = this.getRandomTweets.bind(this);
    }

    getRandomTweets() {
        this.setState({embeds: []});
         axios.get(API_URL + "/api/random-tweets")
            .then(resp => {
                this.setState({embeds: resp.data.embeds});

            }).catch(errors => {

        })
    }

    logout() {
        axios.post(API_URL + "/api/logout")
            .then(resp => {
 window.location.href = "/";
                window.location.reload();
            }).catch(errors => {

        })
    }
    componentDidMount() {
       this.getRandomTweets();
    }

    render() {
        return (
            <div className={"home"} style={{overflow: "scroll", display: "flex", flexDirection: "column", alignItems: "center"}}>

                <div style={{width: window.innerWidth <= 700 ? "90%" : 700, height: 50, paddingTop: 10, display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{color: "white", fontSize: 25, fontWeight: "bold"}}>TweetMinder</div>

                    <div style={{marginLeft: "auto", color: "white", display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <div
                            onClick={this.getRandomTweets}
                            className={"hover"} style={{marginRight: 15, backgroundColor: "red", color: "white", padding: 10, fontWeight: "bold", borderRadius: 8}}>Random</div>
                        <div
                            onClick={this.logout}
                            className={"hover"}>Logout</div>
                    </div>
                </div>

                 {
                    this.state.embed == null &&
                         <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        style={{marginTop: 30}}
        width={100}
        timeout={3000} //3 secs
      />
                }


                <div style={{width: window.innerWidth <= 700 ? "90%" : 700, display: "flex", alignItems: "center", flexDirection: "column", marginTop: 30}}>
                    <div style={{marginTop: 10, width: "100%"}}>

                    {
                        this.state.embeds != null && this.state.embeds.map(embed =>
                            <div style={{marginLeft: window.innerWidth < 700 ? 0 : 75, marginTop: 20}}>
                           <TwitterTweetEmbed
  tweetId={embed}
  options={{width:window.innerWidth < 700 ? "95%" : 600}}
/>
                                                            </div>
                        )

                    }
                    </div>
                    </div>


            </div>
        )
    }
}

const Loading = (props) => {
    return (
        <div className={"App-header"} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
           <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
        </div>
    )
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            authenticated: null,
            ret: null,
        }
    }

    componentDidMount() {
        axios.get(API_URL + "/api/is_authenticated")
            .then(resp => {
                this.setState({authenticated: true, user: resp.data, ret: true});
            })
            .catch(error => {
                this.setState({authenticated: false, ret: true});
            })
    }

    render() {
        return (
            <div>
                {
                    this.state.authenticated && Object.keys(this.state.user).length > 0 && this.state.ret == true?
                         <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={() => <Home/>}/>

                  <Redirect from='*' to='/' />
                  </Switch>
                </BrowserRouter>
                        :
                        this.state.ret == true ?
                         <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={() => <LandingPage/>}/>
                  <Route path="/twitter" component={() => <TwitterAuth/>}/>
                  <Redirect from='*' to='/' />
                  </Switch>
                </BrowserRouter>
                            :
                             <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={() => <Loading/>}/>
                                      <Route path="/twitter" component={() => <TwitterAuth/>}/>
                  <Redirect from='*' to='/' />
                  </Switch>
                </BrowserRouter>


                }



            </div>
        )
    }
}

export default App;
